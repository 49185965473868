import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "../Carousel/Carousel";
import "./Researches.scss";

const Researches = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [finished, setFinished] = useState(false);
  const [funding, setFunding] = useState(false);
  const [filters, setFilters] = useState("");
  const { t } = useTranslation("research");

  const data = t("researches");
  const backgroundImage = () => {
    const bgImg = require(`../../../../assets/images/${data[selectedIndex].description.image.src}`);
    return {
      backgroundImage: `url(${bgImg})`
    };
  };

  const handleClick = reference => {
    reference === "inProgress"
      ? setInProgress(!inProgress)
      : setInProgress(false);
    reference === "finished" ? setFinished(!finished) : setFinished(false);
    reference === "funding" ? setFunding(!funding) : setFunding(false);
  };

  useEffect(() => {
    const filters = {
      inProgress,
      finished,
      funding
    };
    setFilters(filters);
  }, [inProgress, finished, funding]);
  console.log(data[selectedIndex].description.image.src)
  return (
    <section className="Researches" style={backgroundImage()}>
      <div className="container">
        <h2>{t("title")}</h2>
        {/* <article className="status">
          <div className="status-desktop">
            <Checkbox
              updateState={setInProgress}
              label={t("inputs.inProgress")}
              value={inProgress}
            />
            <Checkbox
              label={t("inputs.finished")}
              value={finished}
              updateState={setFinished}
            />
            <Checkbox
              label={t("inputs.funding")}
              value={funding}
              updateState={setFunding}
            />
          </div>
          <div className="status-mobile">
            <p
              className={inProgress ? "selected" : null}
              onClick={() => handleClick("inProgress")}
            >
              {t("inputs.inProgress")}
            </p>
            <p
              className={finished ? "selected" : null}
              onClick={() => handleClick("finished")}
            >
              {t("inputs.finished")}
            </p>
            <p
              className={funding ? "selected" : null}
              onClick={() => handleClick("funding")}
            >
              {t("inputs.funding")}
            </p>
          </div>
        </article> */}
        <article className="description-container">
          <Carousel
            researchesData={data}
            setSelected={setSelectedIndex}
            selected={selectedIndex}
            filters={filters}
          />
          <div className="container-info">
            <img
              className="research-image"
              src={require(`../../../../assets/images/${data[selectedIndex].description.image.src}`)}
              alt={`researches.${selectedIndex}.researchName`} />
            <div className="title-description">
              <h3>{t(`researches.${selectedIndex}.researchName`)}</h3>
              <p className="project-status">
                {t(`researches.${selectedIndex}.status`)}
              </p>
              <p dangerouslySetInnerHTML={{ __html: t(`researches.${selectedIndex}.description.text`) }} />
              {/* <Link
                to={{
                  pathname:
                    data[selectedIndex].site === ""
                      ? `/pesquisa/${selectedIndex}/problema`
                      : data[selectedIndex].site
                }}
                target={data[selectedIndex].site !== "" && "_blank"}
              >
                <button>{t("buttons.knowMore")}</button>
                <span>
                  <PlusIcon />
                </span>
              </Link> */}
            </div>
          </div>

        </article>
        <div className="message-action">
          <p dangerouslySetInnerHTML={{ __html: t("related.textPrimary") }} />
          <p dangerouslySetInnerHTML={{ __html: t("related.textSecond") }} />
          <a href="">
            <button>{t("related.textButton")}</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Researches;
